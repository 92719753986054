/* You can add global styles to this file, and also import other style files */
@import "~@citypantry/pattern-library/dist/styles/main.css";
@import "~@citypantry/pattern-library/dist/scss/export.scss";

@import "~dragula/dist/dragula.min.css";

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

@import "~@angular/cdk/overlay-prebuilt.css";

html, body {
  min-height: 100%;
}

html.printable .no-print-on-printable {
  @media print {
    display: none;
  }
}
